var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "padding-bottom": "50px" } },
    [
      _vm.hasEnt ? _c("p", [_vm._v("请选择权限： ")]) : _vm._e(),
      _c("a-tree", {
        attrs: {
          "tree-data": _vm.treeData,
          replaceFields: _vm.replaceFields,
          checkable: true,
        },
        model: {
          value: _vm.checkedKeys,
          callback: function ($$v) {
            _vm.checkedKeys = $$v
          },
          expression: "checkedKeys",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }