var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-drawer",
    {
      attrs: {
        visible: _vm.isShow,
        title: _vm.isAdd ? "新增角色" : "修改角色",
        width: "30%",
        maskClosable: false,
      },
      on: {
        close: function ($event) {
          _vm.isShow = false
        },
      },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "infoFormModel",
          attrs: {
            model: _vm.saveObject,
            "label-col": { span: 4 },
            "wrapper-col": { span: 15 },
            rules: _vm.rules,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "角色名称：", prop: "roleName" } },
            [
              _c("a-input", {
                model: {
                  value: _vm.saveObject.roleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.saveObject, "roleName", $$v)
                  },
                  expression: "saveObject.roleName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("RoleDist", { ref: "roleDist" }),
      _c(
        "div",
        { staticClass: "drawer-btn-center" },
        [
          _c(
            "a-button",
            {
              style: { marginRight: "8px" },
              attrs: { icon: "close" },
              on: {
                click: function ($event) {
                  _vm.isShow = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.confirmLoading,
                icon: "check",
              },
              on: { click: _vm.handleOkFunc },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }